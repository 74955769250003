import React from 'react';
import VP from '../Images/VPHomePage.jpg';
import Crypto from '../Images/CryptoBetting.png';
import Unity from '../Images/UnityGame.png';

function Projects() {
    return (
        <div className="bg-coal-900 min-h-screen text-coal-100">
            <div className="lg:text-6xl text-3xl font-light p-10">
                PROJECTS
            </div>
            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <a href="https://visual-portfolio.onrender.com" target="_blank" rel="noreferrer" className="font-light lg:text-4xl text-xl text-coal-100 underline hover:text-blue-200">Visual Portfolio</a>
                <div className="flex flex-row pt-5">
                    <img
                        src={VP}
                        className="sm:h-5 lg:h-60 lg:w-auto sm:w-1/2 m-auto pt-5"
                        alt=""
                    />
                </div>

                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    The <a href="https://visual-portfolio.onrender.com" target="_blank" rel="noreferrer" className="text-coal-100 underline hover:text-blue-200">Visual Portfolio App</a>
                    &nbsp; is an app that me and a small team developed during my Software Development II class.
                    The functionality of the app is a Drag and Drop resume builder. In this app the user can
                    quickly build a custom resume using a Drag and Drop system. The app stores any of the traits you add
                    so that upon creating a new document it will be ready for use. One thing we did not get to implement
                    due to time constraints, was a login system. At the moment the page is connected to one database so
                    anyone on the app can see all the documents and traits.

                    <br/>
                    <br/>

                    <u>This App Was Developed Using</u>

                    <ul>
                        <li>React</li>
                        <li>Javascript</li>
                        <li>Tailwind</li>
                        <li>Firebase</li>
                        <li>D3.js Library</li>
                    </ul>

                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <a href="https://crypto-betting-app.netlify.app" target="_blank" rel="noreferrer" className="font-light lg:text-4xl text-xl text-coal-100 underline hover:text-blue-200">Crypto Betting App</a>
                <div className="flex flex-row pt-5">
                    <img
                        src={Crypto}
                        className="sm:h-5 lg:h-60 lg:w-auto sm:w-1/2 m-auto pt-5"
                        alt=""
                    />
                </div>
                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    The <a href="https://crypto-betting-app.netlify.app" target="_blank" rel="noreferrer" className="text-coal-100 underline hover:text-blue-200">Crypto Betting App</a> was my Capstone project that I developed with a
                    small team. This app allows user to place bets on whether they think the price of a particular crypto
                    currency was going to go up or down by a certain percent in a time period. The app would get the price history
                    for a select cryptos and display the chart of how it is performing for the day or over a 14 day period. The user
                    is then allowed a create a bet that other users can see and place wagers on. When the time on the bet expires the
                    users are rewarded tokens if their wager was correct. This website also a an active leaderboard where you can see which
                    users had the most tokens accumulated from their wagers.

                    <br/>
                    <br/>

                    <u>This App Was Developed Using</u>

                    <ul>
                        <li>React</li>
                        <li>Typescript</li>
                        <li>Tailwind</li>
                        <li>Mongo DB</li>
                        <li>CoinGecko's API</li>
                        <li>HighCharts Library</li>
                        <li>Auth0 for login authentication</li>
                    </ul>
                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Game Dev Project</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={Unity}
                        className="sm:h-5 lg:h-60 lg:w-auto sm:w-1/2 m-auto pt-5"
                        alt=""
                    />
                </div>
                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    This project was an assigment that I  developed by myself for my Game Development Class. While the game seems rudimentary,
                    I still am very proud of it (and it is the only game dev project I still have after an unfortunate server crash).
                    This game was a physics based puzzle platformer, with the level pictured above requiring the player to grab the crate and
                    move it so that they can use it as a step to climb the hill and grab the objective.

                    <br/>
                    <br/>

                    <u>This Game Was Developed Using</u>
                    <ul>
                        <li>C#</li>
                        <li>Unity Game Engine</li>
                    </ul>

                </div>

            </div>
            <br/>
            <br/>
        </div>
    );
}

export default Projects;