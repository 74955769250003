import React from 'react';
import Disc from '../Images/Disc.jpg';
import Game from '../Images/Game.JPG';
import Gaming from '../Images/Gaming.jpg';
import Rejoice from '../Images/REJOICE FIXED.jpg';
import AList from '../Images/AList.jpg';




function Hobbies() {
    return (
        <div className="bg-coal-900 min-h-screen text-coal-100">
            <div className="lg:text-6xl text-3xl font-light p-10">
                HOBBIES
            </div>
            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Disc Golf</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={Disc}
                        className="lg:h-96 h-40 w-auto m-auto rounded-3xl"
                        alt=""
                    />
                </div>

                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    Full transparency despite the above scorecard, I am not a good disc golfer, but that doesn't discourage
                    me from playing. I like disc golf because it gives me an opportunity to go outside and be active. I think of
                    disc golf as hiking with an objective. This is because I like hiking but I can't mentally justify myself
                    to just go hiking. With some disc golf courses being in heavily wooded/mountainous regions, this allows me
                    to experience all the great things of hiking with the fun challenge of playing a game throughout.

                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Board Games</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={Game}
                        className="lg:h-96 h-40 w-auto m-auto rounded-3xl"
                        alt=""
                    />
                </div>
                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    Ever since I was young I always appreciated the idea of sitting around friends and family and playing
                    a fun board game together. The teamwork, the backstabbing, the luck, the strategy, the wins, and even the losses
                    are so magical and are why I love board games.

                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Video Games</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={Gaming}
                        className="lg:h-96 h-40 w-auto m-auto rounded-3xl"
                        alt=""
                    />
                </div>
                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    When I was a kid my parents told me that playing video games was a phase that I was going to grow out
                    of. I don't think they could've been more wrong. To me video games were an escape a place where I can
                    just sit down and recharge. As for favorite types of games, I love platformers, and story driven games.

                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Making Things</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={Rejoice}
                        className="lg:h-96 h-48 w-auto m-auto rounded-3xl"
                        alt=""
                    />
                </div>
                <div className="flex flex-row pt-5">

                </div>

                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    To me there is nothing more satisfying than seeing something that you made. For me I can experience this
                    with more structured builds like puzzles and lego sets, But nothing tops something you thought up, designed, planned,
                    and executed. Above I have pictures of a few of the different things I have built and my favorite of these is
                    the Rejoice Sign. The story is that the church I work at was about to have Christmas Eve Services. So our worship leader and me
                    decided to make a Rejoice Sign for outside. This sign was constructed using poster-board that we spray painted to give
                    a weathered copper look and illuminated with some string lights. The planning and designing of these took nearly a week and if
                    given the opportunity I would do it again.

                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Movies</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={AList}
                        className="lg:h-96 h-40 w-auto m-auto rounded-3xl"
                        alt=""
                    />
                </div>
                <div className="lg:text-xl text-sm font-light leading-10 pt-10 lg:px-10 sm:px-5">
                    Movies have always been apart of my life. From when I was young and would wear out my favorite VHS tapes, to now
                    where I am constantly looking and watching new movies whether they are in theaters or streaming services. Now I know
                    what you must be thinking "Everyone likes movies", but I have to counter and say "Not as much as me". Anyone who knows
                    me can tell you that I make it habit of going to the theater multiple times a week. As you can see from the picture above
                    I am apart of AMC A-List which allows me to see up to 3 movies a week for free, and I abuse this system. I dont want to disclose
                    how many movies I see (because its embarrassing), but rest assured it is a lot.
                </div>
            </div>

            <br/>
            <br/>
        </div>
    );
}

export default Hobbies;