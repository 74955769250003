import React from 'react';
import {Link} from "react-router-dom";

function NavBar() {

    return(
        <div className="w-screen h-20 bg-coal-700 font-bold lg:p-5 pt-7 sticky top-0">
        <div className="flex">
            <div className="text-coal-100 w-1/6 h-1/2 sm:text-sm lg:text-2xl m-auto hover:text-blue-200 font-light">
                <Link to="/">Home</Link>
            </div>
            <div className="text-coal-100 w-1/6 h-1/2 sm:text-sm lg:text-2xl m-auto hover:text-blue-200 font-light">
                <Link to="/projects">Projects</Link>
            </div>
            <div className="text-coal-100 w-1/6 h-1/2 sm:text-sm lg:text-2xl m-auto hover:text-blue-200 font-light">
                <Link to="/hobbies">Hobbies</Link>
            </div>
            <div className="text-coal-100 w-1/6 h-1/2 sm:text-sm lg:text-2xl m-auto hover:text-blue-200 font-light">
                <Link to="/about">About</Link>
            </div>
            <div className="text-coal-100 w-1/6 h-1/2 sm:text-sm lg:text-2xl m-auto hover:text-blue-200 font-light">
                <Link to="/contact">Contact</Link>
            </div>
        </div>
        </div>
    )
}

export default NavBar;