import React from 'react';
import GGC from '../Images/GGC.png';

function About() {
    return (
        <div className="bg-coal-900 min-h-screen text-coal-100">
            <div className="lg:text-6xl text-3xl font-light p-10">
                ABOUT ME
            </div>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Education</div>
                <div className="flex flex-row pt-5">
                    <img
                        src={GGC}
                        className="lg:h-96 h-40 w-auto m-auto pt-5 rounded-3xl"
                        alt=""
                    />
                </div>

                <div className="lg:text-3xl text-lg p-5">
                   Graduated May 2023
                </div>

                <div className="lg:text-2xl text-sm-s p-5">
                    Bachelors in Science
                    <br/>
                    With a Major in Information Technology with a concentration in Software Development
                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto p-5 font-light">
                <div className="lg:text-4xl text-xl">Work Experience</div>

                <div className="lg:text-2xl text-lg pt-10 underline">
                    Victory Baptist School
                </div>

                <div className="lg:text-xl text-sm p-3">
                    Teacher's Assistant
                    <br/>
                    January 2018 - May 2018
                </div>

                <div>
                    This job had me help teach kids how to plan, shoot, and edit videos for a class.
                </div>

                <div className="lg:text-2xl text-lg pt-10 underline">
                    Brownlee's Furniture
                </div>

                <div className="lg:text-xl text-sm p-3">
                    Warehouse Associate
                    <br/>
                    May 2018 - August 2018
                </div>

                <div>
                    This was my summer job straight out of high school. This job required me to speak with customers
                    and fulfill their orders.
                </div>

                <div className="lg:text-2xl text-lg pt-10 underline">
                    Graystone Church
                </div>

                <div className="lg:text-xl text-sm p-3">
                    Oconee Production Director
                    <br/>
                    August 2018 - Present
                </div>

                <div>
                    This job has me plan the production for our services and lead/teach a team of volunteers how to run everything
                    effectively.
                </div>
            </div>

            <br/>
            <br/>

            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto font-light">
                <div className="lg:text-4xl text-xl">Skills</div>

                <div className="flex flex-row m-auto justify-between flex-wrap">
                    <div className="lg:w-auto w-3/4 pt-1 m-auto lg:pl-0 pl-14">
                        <ul className="list-inside list-disc p-3 text-start lg:text-lg text-base">
                            <li>Leadership</li>
                            <li>Communication</li>
                            <li>Problem Solving</li>
                        </ul>
                    </div>
                    <div className="lg:w-auto w-3/4 pt-1 m-auto lg:pl-0 pl-14">
                        <ul className="list-inside list-disc p-3 text-start lg:text-lg text-base">
                            <li>Java</li>
                            <li>Javascript</li>
                            <li>Typescript</li>
                        </ul>
                    </div>
                    <div className="lg:w-auto w-3/4 pt-1 m-auto lg:pl-0 pl-14">
                        <ul className="list-inside list-disc p-3 text-start lg:text-lg text-base">
                            <li>C#</li>
                            <li>Teamwork</li>
                            <li>React Dev.</li>
                        </ul>
                    </div>

                </div>


            </div>

            <br/>
            <br/>
        </div>
    );
}

export default About;