import './App.css';
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import About from "./Pages/About";
import Hobbies from "./Pages/Hobbies";
import {Route,Routes} from "react-router-dom";
import NavBar from "./Components/NavBar";
import Contact from "./Pages/Contact";

function App() {
  return (
    <div className="App">
        <NavBar />
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/*' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/projects' element={<Projects/>}/>
            <Route path='/hobbies' element={<Hobbies/>}/>
            <Route path='/contact' element={<Contact/>}/>
        </Routes>
    </div>
  );
}

export default App;
