import React from 'react';
import LinkedIn from '../Images/LinkedIn.png';
import Git from '../Images/GitHub.png';
import mail from '../Images/Gmail.png';

function Contact() {
    return (
        <div className="bg-coal-900 min-h-screen text-coal-100">
            <div className="lg:text-6xl text-3xl font-light p-10">
                CONTACT ME
            </div>

                <div className="flex flex-row flex-wrap lg:pt-24 m-auto">

                    <div className="m-auto transform transition duration-500 hover:scale-110">
                        <a href="https://www.linkedin.com/in/canaan-hanley-253447222/" target="_blank" rel="noreferrer">
                             <img
                                src={LinkedIn}
                                className="h-56 w-auto m-auto p-5 lg:pt-7 pb-10 rounded-3xl"
                                alt=""
                         />
                    </a>
                    </div>

                    <div className="m-auto transform transition duration-500 hover:scale-110 bg-coal-100 rounded-2xl">
                        <a href="https://github.com/CanaanHanley" target="_blank" rel="noreferrer">
                            <img
                                src={Git}
                                className="lg:h-56 h-44 w-auto m-auto p-3 rounded-3xl"
                                alt=""
                            />
                        </a>
                    </div>

                    <div className="m-auto transform transition duration-500 hover:scale-110 rounded-2xl">
                        <a href="mailto:canaanhanley@gmail.com">
                            <img
                                src={mail}
                                className="h-56 w-auto m-auto p-3 rounded-3xl"
                                alt=""
                            />
                        </a>
                    </div>
                </div>

                <div className="text-2xl">

                </div>


            <br/>
            <br/>
        </div>
    );
}

export default Contact;