import React from 'react';
import headshot from '../Images/Headshot.jpg'

function Home() {
    return (
        <div className="bg-coal-900 min-h-screen">
        <div className="p-5 text-2xl text-coal-100">
            <div className="lg:text-6xl text-3xl font-light pt-5">
                    I'm Canaan Hanley
            </div>
            <div className="lg:text-3xl text-xl font-light pt-5">
                Welcome to My Website
            </div>
            <div className="mx-auto mb-4">
                <img
                    className="lg:h-80 h-40 lg:w-80 w-36 rounded-full m-auto pt-5"
                    src={headshot}
                    alt=""
                />
            </div>
            <div className="w-9/12 bg-coal-800 rounded-2xl m-auto lg:p-5 p-2 lg:text-xl text-sm font-light leading-10">
                Hello there my name is Canaan B. Hanley and this is my website. I graduated from Georgia Gwinnett College with
                Bachelors Degree in ITEC with a concentration in Software Development. I'm creating this website as a way for people to learn about who I am, what are my hobbies,
                and what my skills are.
            </div>
        </div>
        </div>
    );
}

export default Home;